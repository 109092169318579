import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { BoxGateway } from '../../components/device-detail/BoxGateway';
import { BoxActions } from '../actions/box-detail';
import { getRebootBoxCommandData } from '../selectors/box-detail';

@Injectable()
export class BoxDetailEffects {
    rebootBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.boxRebootRequested),
            switchMap(({ deviceId }) => this.storeService.select(getRebootBoxCommandData(deviceId))),
            switchMap((command) =>
                this.boxGateway
                    .reboot(command)
                    .pipe(map((pendingTask) => BoxActions.boxRebootStarted({ command, pendingTask }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        @Inject('BoxGateway') private boxGateway: BoxGateway,
        private storeService: StoreService<AppState>,
    ) {}
}
