$light-yellow: #ffe393;
$yellow: #fab800;
$dark-yellow: #f7b500;
$orange: #ff6c32;
$warning-color: #f57676;
$red: #d0021b;
$light-red: #ff510f;
$fire-orange: #ff6c32;
$green: #08c27f;
$dark-smart-blue: #1e3a48;
$smart-blue: #25485a;
$dark-slate-grey: #27384e;
$light-smart-blue: #667983;
$lightning-blue: #008cb9;
$blue: #485c74;
$blue-grey: #8996a4;
$grey: #a6b0ba;
$light-grey: #cacfd5;
$light-beige: #e3dbd1;
$very-light-pink: #d1d1d1;
$dark-pearl: #e0e4e8;
$pearl: #f3f5f8;
$white: #ffffff;

$font-family-title: SomfySansMedium;
$font-family-body: SomfySansRegular;
