import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { VideoCallProvider } from '../../services/VideoCallProvider';
import { UiVideoCallsActions } from '../actions/uiVideoCall';

@Injectable()
export class UIVideoCallEffects {
    turnOnMicrophone$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UiVideoCallsActions.turnedOnMicrophone),
                tap(() => {
                    this.videoCallProvider.turnOnMicrophone();
                }),
            ),
        { dispatch: false },
    );

    turnOffMicrophone$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UiVideoCallsActions.turnedOffMicrophone),
                tap(() => {
                    this.videoCallProvider.turnOffMicrophone();
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
    ) {}
}
