import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import { LinkRequestsService } from '../../services/link-requests.service';
import { LinkRequestsActions } from '../actions/link-requests';

@Injectable()
export class LinkRequestEffects {
    loadLinkRequestsRequestsAfterInitialization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initialized),
            switchMap(() => this.linkRequestService.getAllLinkRequestPendingRequest()),
            map((linkRequests) => LinkRequestsActions.loadLinkRequests({ linkRequests })),
        ),
    );

    constructor(
        private actions$: Actions,
        private linkRequestService: LinkRequestsService,
    ) {}
}
