import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VIDEO_CALL_EVENT_TYPE } from '../../../video-conference/models/video-call-event';
import { VideoCallsActions } from '../../../video-conference/store/actions/videoCall';
import { UISocketDataMessage } from '../../models/UISocket';
import { VideoCallEventBase } from '../../models/VideoCallEventBase';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketJoinedParticipantVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT;
    userName: string;
    sessionId: string;
    url: string;
    ownerToken: string;
    isOwner: boolean;
}

export interface UISocketJoinedParticipantVideoCallEventMessage extends UISocketDataMessage {
    videoCallEvent: UISocketJoinedParticipantVideoCallEvent;
}

@Injectable()
export class VideoCallJoinedParticipantHandler implements SocketActionHandler {
    readonly action: SocketAction = 'joined_participant';

    constructor(private store: Store<AppState>) {}

    handle({ videoCallEvent: event }: UISocketJoinedParticipantVideoCallEventMessage): void {
        this.store.dispatch(
            VideoCallsActions.videoCallParticipantJoined({
                siteId: event.siteId,
                url: event.url,
                ownerToken: event.ownerToken,
                userName: event.userName,
                isOwner: event.isOwner,
            }),
        );
    }
}
