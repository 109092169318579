import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AlertsMailingConfigActions } from '../../../alerts-notification-config/store/actions/alerts-mailing-config';
import { AlertsNotificationConfigActions } from '../../../alerts-notification-config/store/actions/alerts-notification-config';
import { ErrorService } from '../../../shared/services/error.service';
import { StoreService } from '../../../shared/services/store.service';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { AppState } from '../../../shared/store/app-state';
import { toFullDateTime } from '../../../utils/date';
import { DialogService } from '../../services/dialog.service';
import { DeviceActions } from '../actions/device-detail';
import { setLoadingSiteContentMessage, setSelectedSite } from '../actions/ui';
import { getSiteOwner } from '../selectors/site';
import {
    buildDeviceSectionSyncFailedToasterMessage,
    buildDeviceUpdateFailedToasterMessage,
} from './toasterMessageBuilders';

@Injectable()
export class NotificationsEffects {
    displayWarningMessageAfterSensorUpdateStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.deviceUpdateStarted),
                filter(({ command: { device } }) => device.product_profile === 'window_sensor'),
                tap(() =>
                    this.dialogService.openWarningDialog(
                        'DEVICE_ACTION_INFO_QUEUED_TITLE',
                        'DEVICE_ACTION_INFO_QUEUED',
                    ),
                ),
            ),
        { dispatch: false },
    );

    displayWarningMessageAfterSensorSectionRefreshStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.deviceSectionRefreshStarted),
                filter(({ command: { device } }) => device.categories.includes('sensor')),
                tap(() =>
                    this.dialogService.openWarningDialog(
                        'DEVICE_REFRESH_INFO_QUEUED_TITLE',
                        'DEVICE_ACTION_INFO_QUEUED',
                    ),
                ),
            ),
        { dispatch: false },
    );

    notifyUserWhenDeviceSectionRefreshFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceSectionRefreshFailed),
            mergeMap((refreshCommand) =>
                this.storeService
                    .select(getSiteOwner(refreshCommand.device.site_id))
                    .pipe(map((owner) => buildDeviceSectionSyncFailedToasterMessage(refreshCommand, owner))),
            ),
            map((message) => addToasterMessage({ message })),
        ),
    );

    notifyUserWhenDeviceUpdateFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateFailed),
            mergeMap((updateCommand) =>
                this.storeService
                    .select(getSiteOwner(updateCommand.device.site_id))
                    .pipe(map((owner) => buildDeviceUpdateFailedToasterMessage(updateCommand, owner))),
            ),
            map((toasterMessage) => addToasterMessage({ message: toasterMessage })),
        ),
    );

    notifyDeviceLoading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceSelected),
            map(() => this.translateService.instant('EQUIPMENT_DETAIL_STATE_SECTION_UPDATING')),
            map((loadingMessage) => setLoadingSiteContentMessage({ loadingMessage })),
        ),
    );

    notifyDeviceLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.allDevicesSynchronized),
            switchMap(() =>
                this.actions$.pipe(
                    ofType(DeviceActions.deviceLoaded),
                    takeUntil(this.actions$.pipe(ofType(setSelectedSite))),
                ),
            ),
            map(() => this.translateService.instant('SITE_SYNCH_DATE', { date: toFullDateTime() })),
            map((loadingMessage) => setLoadingSiteContentMessage({ loadingMessage })),
        ),
    );

    notifyUserWhenAlertConfigUpdateFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AlertsNotificationConfigActions.alertNotificationConfigUpdateFailed,
                    AlertsMailingConfigActions.apiFailed,
                ),
                tap(() =>
                    this.errorService.showToasterError('ERROR_WHILE_UPDATING_ALERTS_CONFIG', { duration: 15_000 }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private storeService: StoreService<AppState>,
        private translateService: TranslateService,
    ) {}
}
