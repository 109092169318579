import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { BoxActions } from '../actions/box-detail';
import { DeviceActions } from '../actions/device-detail';
import { addPendingInterventionAction } from '../actions/ui';
import {
    getPendingBoxRebootActionCreationData,
    getPendingDeviceActionCreationData,
} from '../selectors/intervention-actions';

@Injectable()
export class InterventionEffects {
    addPendingActionsAfterDeviceUpdateStarted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateStarted),
            switchMap(({ command, pendingTask }) =>
                this.storeService.select(getPendingDeviceActionCreationData(command, pendingTask)),
            ),
            map((interventionAction) => addPendingInterventionAction({ interventionAction })),
        ),
    );

    addPendingInterventionWhenRebootBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoxActions.boxRebootStarted),
            switchMap(({ command, pendingTask }) =>
                this.storeService.select(getPendingBoxRebootActionCreationData(command, pendingTask)),
            ),
            map((interventionAction) => addPendingInterventionAction({ interventionAction })),
        ),
    );

    constructor(
        private actions$: Actions,
        private storeService: StoreService<AppState>,
    ) {}
}
