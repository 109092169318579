import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, first, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertResolutionActions } from '../../../alert-resolution/store/actions/alert-resolution';
import { AlertConfig } from '../../../alerts-notification-config/models/AlertsNotificationConfig';
import { AlertsNotificationConfigActions } from '../../../alerts-notification-config/store/actions/alerts-notification-config';
import {
    getAlertConfig,
    getAlertsConfigByProductType,
} from '../../../alerts-notification-config/store/selectors/alerts-notification-config';
import { Alert, AlertId } from '../../../alerts/models/Alert';
import { AlertsActions, AlertsPageActions } from '../../../alerts/store/actions/alerts';
import { getAlertById } from '../../../alerts/store/selectors/alerts';
import {
    AlertLogEvent,
    AlertsNotificationConfigLogEvent,
    AnalyticsService,
} from '../../../shared/services/analytics.service';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { DEFAULT_LOCALE } from '../../../utils/url';
import protocolProfileToProductTypeMapping from '../../components/devices/device-list/protocol-profile-to-product-type.mapping';
import { BoxActions } from '../actions/box-detail';
import { DeviceActions } from '../actions/device-detail';
import { SiteInstallationPageActions } from '../actions/site';
import { getRawDevice } from '../selectors/device-detail';

@Injectable()
export class AnalyticsEffects {
    logDeviceEditionRequested$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.deviceUpdateRequested, AlertResolutionActions.deviceUpdateRequested),
                tap((updateRequest) => {
                    this.storeService.select(getRawDevice(updateRequest.deviceId)).subscribe((device) => {
                        const product_type = protocolProfileToProductTypeMapping[device.protocol_profile];
                        const source = updateRequest.type.includes('[Device]') ? 'Installation' : 'Alert Resolution';
                        this.analyticsService.editDeviceParameter(product_type, updateRequest.property.id, source);
                    });
                }),
            ),
        { dispatch: false },
    );

    logDeviceSectionRefreshRequested$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.deviceSectionRefreshRequested),
                switchMap(({ deviceId }) => this.storeService.select(getRawDevice(deviceId))),
                tap((device) => {
                    const currentLang = this.translateService.currentLang || DEFAULT_LOCALE;
                    this.translateService.use('en').subscribe(() => {
                        const productName = this.translateService.instant(device.protocol_profile);
                        this.analyticsService.refreshDeviceSection(productName);
                        this.translateService.use(currentLang).subscribe();
                    });
                }),
            ),
        { dispatch: false },
    );

    logRebootBoxRequested$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BoxActions.boxRebootRequested),
                tap(() => {
                    this.analyticsService.rebootBox();
                }),
            ),
        { dispatch: false },
    );

    logAlertsNotificationConfigGroupChanges$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsNotificationConfigActions.alertNotificationGroupToggled),
                switchMap((action) =>
                    combineLatest([
                        of(action),
                        this.storeService.select(getAlertsConfigByProductType(action.productType)),
                    ]),
                ),
                map(([{ isActive }, alertsConfigByProductType]) =>
                    alertsConfigByProductType
                        .filter((alertConfig) => alertConfig.isActive != isActive)
                        .map((alertConfig) => this.buildAlertConfigEvent({ ...alertConfig, isActive })),
                ),
                tap((logEvents) => {
                    logEvents.forEach((event) => this.analyticsService.logAlertsNotificationConfigChanges(event));
                }),
            ),
        { dispatch: false },
    );

    logAlertsNotificationConfigChange$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsNotificationConfigActions.alertNotificationToggled),
                switchMap((action) =>
                    combineLatest([
                        of(action),
                        this.storeService.select(getAlertConfig(action.name, action.productType)),
                    ]),
                ),
                tap(([{ isActive }, alertConfig]) => {
                    const alertConfigEvent = this.buildAlertConfigEvent({ ...alertConfig, isActive });
                    this.analyticsService.logAlertsNotificationConfigChanges(alertConfigEvent);
                }),
            ),
        { dispatch: false },
    );

    logAlertDeleted$ = this.logAlertEvent(
        'alert_deleted',
        AlertsPageActions.alertResolutionRequested,
        AlertResolutionActions.alertResolutionRequested,
        DeviceActions.alertResolutionRequested,
    );

    logAlertDetailRequested$ = this.logAlertEvent('alert_detail_click', AlertsActions.alertDetailRequested);

    logAlertResolutionRequested$ = this.logAlertEvent(
        'alert_resolution_started',
        AlertsPageActions.alertResolutionPageRequested,
        DeviceActions.alertResolutionPageRequested,
    );

    logContactSupportRequested$ = this.logAlertEvent(
        'alert_contact_support_requested',
        AlertResolutionActions.contactSupportRequested,
    );

    logInterventionRequested$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertResolutionActions.interventionRequested, SiteInstallationPageActions.interventionRequested),
                tap(({ type: eventType }) => {
                    const source = eventType.includes('[AlertResolution]') ? 'Alert resolution' : 'Installation';
                    this.analyticsService.remoteServiceRequested(source);
                }),
            ),
        { dispatch: false },
    );

    logAlertSearchUsed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsPageActions.opened),
                switchMap(() =>
                    combineLatest([
                        this.actions$.pipe(ofType(AlertsPageActions.searchUpdated)),
                        this.actions$.pipe(ofType(AlertsPageActions.closed)),
                    ]),
                ),
                tap(() => this.analyticsService.logAlertSearched()),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private analyticsService: AnalyticsService,
        private translateService: TranslateService,
        private storeService: StoreService<AppState>,
    ) {}

    private logAlertEvent(
        eventId: string,
        ...actions: ActionCreator<string, (props: { alertId: AlertId }) => { alertId: AlertId }>[]
    ) {
        return createEffect(
            () =>
                this.actions$.pipe(
                    ofType(...actions),
                    switchMap(({ alertId }) => this.storeService.select(getAlertById(alertId)).pipe(first(Boolean))),
                    switchMap((alert) => this.buildAlertEvent(alert)),
                    tap((event) => this.analyticsService.logAlertEvent(eventId, event)),
                ),
            { dispatch: false },
        );
    }

    private buildAlertEvent(alert: Alert): Observable<AlertLogEvent> {
        const currentLang = this.translateService.currentLang || DEFAULT_LOCALE;
        return this.translateService.use(DEFAULT_LOCALE).pipe(
            map(() => {
                const alertTitle = this.translateService.instant('ALERT_TITLE_' + alert.id_trigger);
                this.translateService.use(currentLang);
                return {
                    trigger_id: alert.id_trigger,
                    alert_title: alertTitle,
                    evt_product_name: alert.device_name,
                };
            }),
        );
    }

    private buildAlertConfigEvent(alertConfig: AlertConfig): AlertsNotificationConfigLogEvent {
        return {
            productType: alertConfig.productType,
            name: alertConfig.name,
            isEnable: alertConfig.isActive ? 'enabled' : 'disabled',
        };
    }
}
