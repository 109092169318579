@use 'variables';

.seg-invalid-input {
  color: variables.$warning-color;
}

.seg-important-instruction {
  font-weight: bold;
}

.seg-violated-instruction {
  font-weight: bold;
  color: variables.$warning-color;
}

.seg-margin-bottom {
  margin-bottom: 10px;
}

.seg-cursor-pointer:hover {
  cursor: pointer;
}

.seg-visible {
  visibility: visible !important;
}
