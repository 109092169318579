import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, timeout } from 'rxjs/operators';
import { ExternalLegalDocument } from '../../../../admin/models/subscription';
import { LegalDocsApi } from '../../../../core/store/api/legalDocs';
import { getSignedLegalDocuments } from '../../../../core/store/selectors/legalDocument';
import { toFullDate } from '../../../../utils/date';
import { getDocumentTranslationAccordingToCurrentUserLang } from '../../../../utils/documents';
import { AnalyticsService } from '../../../services/analytics.service';
import { ErrorService } from '../../../services/error.service';
import { AppState } from '../../../store/app-state';

@Component({
    selector: 'app-modal-signed-document',
    templateUrl: './modal-signed-document.component.html',
    styleUrls: ['./modal-signed-document.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatButton, TranslateModule],
})
export class ModalSignedDocumentComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();

    readonly signedLegalDocuments$: Observable<ExternalLegalDocument[]>;
    signedDocuments: ExternalLegalDocument[];
    downloadInProgress: string[] = [];

    private docTypeMapping = {
        'data privacy': 'data privacy',
        CGU: 'terms and conditions',
    };

    constructor(
        private legalDocApi: LegalDocsApi,
        private translationService: TranslateService,
        private errorService: ErrorService,
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store<AppState>,
        private analyticService: AnalyticsService,
    ) {
        this.signedLegalDocuments$ = this.store.select(getSignedLegalDocuments);
    }

    ngOnInit() {
        this.signedLegalDocuments$.pipe(takeUntil(this.unsubscribe$)).subscribe((docs: ExternalLegalDocument[]) => {
            this.signedDocuments = docs;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    downloadSignedFile(doc: ExternalLegalDocument, translationId: string): void {
        this.downloadInProgress.push(translationId);
        this.legalDocApi
            .getTranslationFileForSubscription(doc.subscription_id, translationId)
            .pipe(
                timeout(15 * 1000),
                catchError((err) => {
                    console.error(err);
                    this.errorService.showToasterError('ADMIN_LEGAL_DOCS_TRANSLATION_DOWNLOAD_ERROR_TEXT');
                    return of();
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((documentURL: string) => {
                this.removeInProgress(translationId);
                if (!documentURL) {
                    return;
                }

                if (doc.display_name in this.docTypeMapping) {
                    const docType = this.docTypeMapping[doc.display_name];
                    this.analyticService.accessGlobalMenuDocument(docType);
                }

                this.pushFileToBrowser(documentURL, doc);
            });
    }

    private pushFileToBrowser(documentURL: string, doc: ExternalLegalDocument) {
        const date = this.computeDate(doc.signature.signature_date);

        // invisible <a> tag for download
        const a = document.createElement('a');
        a.href = documentURL;
        a.download = `${doc.display_name}-${date}.pdf`;
        a.style.position = 'fixed';
        a.target = '_blank';
        a.download = 'pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    private removeInProgress(id: string): void {
        const inProgressIx = this.downloadInProgress.indexOf(id);
        this.downloadInProgress = [
            ...this.downloadInProgress.slice(0, inProgressIx),
            ...this.downloadInProgress.slice(inProgressIx + 1),
        ];
        this.changeDetectorRef.detectChanges();
    }

    isDownloadInProgressForTranslId(id: string): boolean {
        return this.downloadInProgress.indexOf(id) > -1;
    }

    computeDate(value: number): string {
        return toFullDate(value);
    }

    getDocument(doc: ExternalLegalDocument) {
        return getDocumentTranslationAccordingToCurrentUserLang(doc, this.translationService.currentLang);
    }
}
