import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VIDEO_CALL_STATUS, VideoCallId } from '../../../video-conference/models/video-call';
import { VIDEO_CALL_EVENT_TYPE } from '../../../video-conference/models/video-call-event';
import { VideoCallsActions } from '../../../video-conference/store/actions/videoCall';
import { Timestamp } from '../../common.types';
import { UISocketDataMessage } from '../../models/UISocket';
import { UserId } from '../../models/User';
import { VideoCallEventBase } from '../../models/VideoCallEventBase';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketVideoCallInvitationSentEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.INVITATION_SENT;
    id: VideoCallId;
    installerId: UserId;
    createdAt: Timestamp;
    url: string;
    ownerToken: string;
}

export interface UISocketVideoCallInvitationSentEventMessage extends UISocketDataMessage {
    videoCallEvent: UISocketVideoCallInvitationSentEvent;
}

@Injectable()
export class VideoCallInvitationSentHandler implements SocketActionHandler {
    readonly action: SocketAction = 'invitation_sent';

    constructor(private store: Store<AppState>) {}

    handle({ videoCallEvent: event }: UISocketVideoCallInvitationSentEventMessage): void {
        this.store.dispatch(
            VideoCallsActions.videoCallInvitationSent({
                siteId: event.siteId,
                videoCall: {
                    id: event.id,
                    companyId: event.companyId,
                    siteId: event.siteId,
                    installerId: event.installerId,
                    createdAt: event.createdAt,
                    url: event.url,
                    ownerToken: event.ownerToken,
                    status: VIDEO_CALL_STATUS.INVITATION_SENT,
                },
            }),
        );
    }
}
