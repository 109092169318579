import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadMessages } from '../../../messages/store/actions/messages';
import { MessagesApi } from '../../../messages/store/api/messages';
import { ToasterMessageType, ToasterNewMailReceivedMessage } from '../../models/Toaster';
import { UISocketDataMessage } from '../../models/UISocket';
import { addToasterMessage } from '../../store/actions/addToasterMessage';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

export interface UISocketNewInstallerMessageMessage extends UISocketDataMessage {
    title: string;
}

@Injectable()
export class NewInstallerMessageHandler implements SocketActionHandler {
    readonly action: SocketAction = 'new_installer_message';

    constructor(
        private messageAPI: MessagesApi,
        private store: Store<AppState>,
    ) {}

    // #SEG-1047
    handle(message: UISocketNewInstallerMessageMessage): void {
        // todo display a toaster about new messages
        this.messageAPI.getMessageForInstaller().subscribe((messages) => {
            this.store.dispatch(loadMessages({ messages }));
            const successMessage: ToasterNewMailReceivedMessage = {
                id: crypto.randomUUID(),
                content: {
                    message: message.title,
                    title: 'INSTALLER_NEW_MESSAGE_RECEIVED_TITLE',
                },
                type: ToasterMessageType.INSTALLER_RECEIVE_NEW_MESSAGE,
            };
            this.store.dispatch(addToasterMessage({ message: successMessage, duration: 1000 }));
        });
        // todo then reload messages
    }
}
