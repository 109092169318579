.seg-block-center-x {
  margin: 0 auto;
}

// Flex
.seg-flex-justify-center {
  display: flex;
  justify-content: center;
}

.seg-flex-justify-space-around {
  display: flex;
  justify-content: space-around;
}

.seg-flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.seg-flex-justify-center {
  display: flex;
  justify-content: center;
}

.seg-flex-align-center {
  display: flex;
  align-items: center;
}

.seg-flex-column {
  display: flex;
  flex-direction: column;
}

.seg-flex-row {
  display: flex;
  flex-direction: row;
}

.flex-gap-05em {
  display: flex;
  gap: 0.5em;
}

.flex-gap-1em {
  display: flex;
  gap: 1em;
}
