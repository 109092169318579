@use 'variables';
@use 'mixins';
@use 'common/common';

app-header {
  display: block;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 70px;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-family: SomfySansRegular, sans-serif;

  .left {
    display: flex;
    align-items: center;
    $elemHeight: 1.5rem;
    $proSeparatorPadding: 1rem;

    .separator {
      height: 40px;
      border-left: 1px #cacfd5 solid;
    }

    .servego-logo-wrapper {
      margin-left: $proSeparatorPadding;
      $width: 5rem;
      width: $width;

      .serv-e-go-logo {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        height: $elemHeight;
        width: $width;
      }
    }
  }
  .messages-link {
    color: variables.$grey;
  }

  .menu-items {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 100%;

    a,
    .burger {
      text-decoration: none;
      cursor: pointer;

      &.active {
        color: variables.$blue;
      }
    }

    a {
      @include mixins.flex-box-vertical-align-center();
      height: 100%;
      padding: 0 10px;

      .mat-badge-content {
        font-size: 8px;
        background-color: variables.$yellow;
      }

      position: relative;

      span {
        font-size: 16px;
      }
    }
  }
}

.content-wrapper {
  margin-top: 70px;
  @include mixins.hideScrollBars();
  background-color: variables.$pearl;

  @include mixins.safari() {
    margin-right: 0;
  }
  @include mixins.safariIOS() {
    margin-right: 0;
  }
}
