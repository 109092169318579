import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { AlertResolutionActions } from '../../../alert-resolution/store/actions/alert-resolution';
import { InterventionActions } from '../../../interventions/store/actions/interventions';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { DeviceGateway } from '../../components/device-detail/DeviceGateway';
import { UiProfileGateway } from '../../components/device-detail/UiProfileGateway';
import { DeviceActions } from '../actions/device-detail';
import { SiteDevicesActions } from '../actions/devices';
import { getActiveDeviceId, getRefreshSectionData, getUpdateDeviceStateData } from '../selectors/device-detail';

@Injectable()
export class DeviceDetailEffects {
    loadDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceSelected, AlertResolutionActions.deviceSelected),
            filter(({ deviceId }) => deviceId != null),
            switchMap(({ deviceId }) =>
                this.deviceGateway
                    .fetchDevice(deviceId)
                    .pipe(
                        switchMap((device) =>
                            device
                                ? of(DeviceActions.deviceApiRequestSuccess({ device }))
                                : of(DeviceActions.deviceNotFound({ deviceId })),
                        ),
                    ),
            ),
        ),
    );

    reloadDeviceAfterSynch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.allDevicesSynchronized),
            switchMap(() => this.storeService.select(getActiveDeviceId)),
            filter((deviceId) => deviceId != null),
            mergeMap((deviceId) =>
                this.deviceGateway
                    .fetchDevice(deviceId)
                    .pipe(
                        switchMap((device) =>
                            device
                                ? of(DeviceActions.deviceLoaded({ device }))
                                : of(DeviceActions.deviceNotFound({ deviceId })),
                        ),
                    ),
            ),
        ),
    );

    loadUiProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceApiRequestSuccess),
            filter(({ device }) => device != null),
            switchMap(({ device }) =>
                this.uiProfileGateway
                    .fetchUiProfile(device.profileId)
                    .pipe(map((uiProfile) => DeviceActions.deviceLoaded({ uiProfile, device }))),
            ),
        ),
    );

    submitUpdateToServegoAPIAfterUserValidation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceUpdateRequested, AlertResolutionActions.deviceUpdateRequested),
            switchMap((updateRequestedEvent) =>
                this.storeService.select(getUpdateDeviceStateData(updateRequestedEvent)),
            ),
            switchMap((updateCommand) =>
                this.deviceGateway.updateDevice(updateCommand).pipe(
                    map((pendingTask) => DeviceActions.deviceUpdateStarted({ pendingTask, command: updateCommand })),
                    catchError(({ error }) =>
                        error?.message === 'Session Expired'
                            ? of(
                                  InterventionActions.interventionClosed({ sessionId: updateCommand.sessionId }),
                                  DeviceActions.deviceUpdateFailed(updateCommand),
                              )
                            : of(DeviceActions.deviceUpdateFailed(updateCommand)),
                    ),
                ),
            ),
        ),
    );

    refreshSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceSectionRefreshRequested),
            mergeMap(({ deviceId, sectionId }) => this.storeService.select(getRefreshSectionData(deviceId, sectionId))),
            mergeMap(({ device, section: { title: sectionId, properties: states } }) =>
                this.deviceGateway.refreshDeviceStates({ deviceId: device.id, states }).pipe(
                    map((pendingTasks) =>
                        DeviceActions.deviceSectionRefreshStarted({
                            command: { device, sectionId },
                            pendingTasks,
                        }),
                    ),
                    catchError(() => of(DeviceActions.deviceSectionRefreshFailed({ device, sectionId }))),
                ),
            ),
        ),
    );

    updateSiteDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.deviceLoaded),
            mergeMap(({ device }) => [
                SiteDevicesActions.deviceUpdated({ siteId: device.rawData.site_id, device: device.rawData }),
            ]),
        ),
    );

    constructor(
        private actions$: Actions,
        @Inject('UiProfileGateway') private uiProfileGateway: UiProfileGateway,
        @Inject('DeviceGateway') private deviceGateway: DeviceGateway,
        private storeService: StoreService<AppState>,
    ) {}
}
