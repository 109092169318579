import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DeviceMS } from '../../../site-detail/models/Device';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { SiteDevicesActions } from '../../../site-detail/store/actions/devices';
import { getSelectedSite } from '../../../site-detail/store/selectors/site';
import { UISocketDataMessage } from '../../models/UISocket';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketDeviceCreated extends UISocketDataMessage {
    device: DeviceMS;
    siteId: SiteId;
}

@Injectable()
export class DeviceCreatedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'device_created';

    constructor(private store: Store<AppState>) {}

    handle(message: UISocketDeviceCreated): void {
        this.store
            .select(getSelectedSite)
            .pipe(take(1), filter(Boolean))
            .subscribe((selectedSite: Site) => {
                if (selectedSite.id === message.siteId) {
                    this.store.dispatch(
                        SiteDevicesActions.deviceCreated({
                            siteId: message.siteId,
                            device: message.device,
                        }),
                    );
                }
            });
    }
}
