import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DeviceId } from '../../../site-detail/models/Device';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { SiteDevicesActions } from '../../../site-detail/store/actions/devices';
import { getSelectedSite } from '../../../site-detail/store/selectors/site';
import { UISocketDataMessage } from '../../models/UISocket';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketDeviceRemoved extends UISocketDataMessage {
    deviceId: DeviceId;
    siteId: SiteId;
}

@Injectable()
export class DeviceRemovedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'device_removed';

    constructor(private store: Store<AppState>) {}

    handle(message: UISocketDeviceRemoved): void {
        this.store
            .select(getSelectedSite)
            .pipe(take(1), filter(Boolean))
            .subscribe((selectedSite: Site) => {
                if (selectedSite.id === message.siteId) {
                    this.store.dispatch(
                        SiteDevicesActions.deviceRemoved({
                            siteId: message.siteId,
                            deviceId: message.deviceId,
                        }),
                    );
                }
            });
    }
}
