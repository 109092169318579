import { createSelector } from '@ngrx/store';
import { getCurrentIntervention } from '../../../interventions/store/selectors/interventions';
import { RebootBoxCommand } from '../../components/device-detail/BoxGateway';
import { DeviceId } from '../../models/Device';
import { getRawDevice } from './device-detail';

export const getRebootBoxCommandData = (deviceId: DeviceId) =>
    createSelector(
        getRawDevice(deviceId),
        getCurrentIntervention,
        (device, { sessionId }): RebootBoxCommand => ({ device, sessionId }),
    );
