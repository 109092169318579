import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenancePeriodCardComponent } from '../../../maintenance-period/components/maintenance-period-card/maintenance-period-card.component';
import { CreateLinkRequestButtonComponent } from '../../../link-requests/components/create-link-request-button/create-link-request-button.component';
import { FavoriteListComponent } from '../../../site-favorite/components/favorite-list/favorite-list.component';
import { AlertIndicatorComponent } from '../alert-indicator/alert-indicator.component';
import { TabNavigationComponent } from '../tab-navigation/tab-navigation.component';

@Component({
    selector: 'app-dashboard',
    imports: [
        TabNavigationComponent,
        RouterOutlet,
        TranslateModule,
        CreateLinkRequestButtonComponent,
        MaintenancePeriodCardComponent,
        FavoriteListComponent,
        AlertIndicatorComponent,
    ],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {}
