import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VIDEO_CALL_EVENT_TYPE } from '../../../video-conference/models/video-call-event';
import { VideoCallsActions } from '../../../video-conference/store/actions/videoCall';
import { UISocketDataMessage } from '../../models/UISocket';
import { VideoCallEventBase } from '../../models/VideoCallEventBase';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketLeaveVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.LEAVE_CALL;
}

export interface UISocketLeaveVideoCallEventMessage extends UISocketDataMessage {
    videoCallEvent: UISocketLeaveVideoCallEvent;
}

@Injectable()
export class VideoCallLeftHandler implements SocketActionHandler {
    readonly action: SocketAction = 'leave_call';

    constructor(private store: Store<AppState>) {}

    handle({ videoCallEvent: event }: UISocketLeaveVideoCallEventMessage): void {
        this.store.dispatch(VideoCallsActions.videoCallStopped({ siteId: event.siteId }));
    }
}
