import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { accessGrantedServego, loadSiteInfo } from '../../../site-detail/store/actions/site';
import { getSiteById } from '../../../site-detail/store/selectors/site';
import { getUserSiteName } from '../../../site-detail/utils/owner.util';
import { ToasterMessageType, ToasterServegoChangedMessage } from '../../models/Toaster';
import { UISocketDataMessage } from '../../models/UISocket';
import { addToasterMessage } from '../../store/actions/addToasterMessage';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketServegoAccessGrantedMessage extends UISocketDataMessage {
    isServego: boolean;
    siteId: SiteId;
}

@Injectable()
export class ServegoAccessGrantedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'servego_access_granted';

    constructor(private store: Store<AppState>) {}

    handle(message: UISocketServegoAccessGrantedMessage): void {
        this.store.dispatch(
            accessGrantedServego({
                siteId: message.siteId,
                isServego: message.isServego,
            }),
        );

        this.store
            .select(getSiteById(message.siteId))
            .pipe(take(1), filter(Boolean))
            .subscribe((site: Site) => {
                site = Object.assign({}, site, { isServego: message.isServego });
                this.displayToasterForServegoAccess(site);
                this.store.dispatch(loadSiteInfo({ site }));
            });
    }

    private displayToasterForServegoAccess(site: Site) {
        const accessGrantedServegoMessage: ToasterServegoChangedMessage = {
            id: crypto.randomUUID(),
            content: {
                siteId: site.id,
                hasAccess: site.isServego,
                userSiteName: getUserSiteName(site.owner),
            },
            type: ToasterMessageType.SERVEGO_ACCESS_CHANGED,
        };
        this.store.dispatch(addToasterMessage({ message: accessGrantedServegoMessage }));
    }
}
