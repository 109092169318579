@use 'variables';

.seg-bg-color-primary {
  background-color: variables.$yellow;
}

.seg-bg-color-secondary {
  background-color: variables.$blue-grey;
}

.seg-text-color-primary {
  color: variables.$yellow;
}
