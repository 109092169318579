import { createSelector } from '@ngrx/store';
import { getUserLoginState } from '../../../shared/store/selectors/login';
import { RebootBoxCommand } from '../../components/device-detail/BoxGateway';
import { UpdateDeviceCommand } from '../../components/device-detail/DeviceGateway';
import { Execution } from '../../models/Execution';
import { buildPendingDeviceAction, buildPendingRebootAction } from '../effects/buildPendingDeviceAction';
import { getRawDevice, getUiProperty } from './device-detail';

export const getPendingDeviceActionCreationData = (command: UpdateDeviceCommand, pendingTask: Execution) =>
    createSelector(
        getRawDevice(command.device.id),
        getUserLoginState,
        getUiProperty(command.device.id, command.property.id),
        (device, installer, property) =>
            buildPendingDeviceAction(device, installer.id, pendingTask, command, property.formatter),
    );

export const getPendingBoxRebootActionCreationData = (command: RebootBoxCommand, pendingTask: Execution) =>
    createSelector(getRawDevice(command.device.id), getUserLoginState, (device, installer) =>
        buildPendingRebootAction(device, installer.id, pendingTask, command),
    );
